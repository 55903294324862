<template>
  <footer class="text-xs text-center mb-3" :class="{ 'fixed-bottom': fixed }">
    <span>
      (C) Mobilenik 2020. The usage of this site is reserved for Mobilenik
      customers.
    </span>
  </footer>
</template>

<script>
export default {
  props: {
    fixed: {
      type: Boolean,
      default: false
    }
  }
};
</script>
