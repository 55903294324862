<template>
  <div class="d-flex flex-column h-100">
    <mn-header />
    <router-view />
    <mn-footer />
  </div>
</template>

<script>
import MnHeader from "@/components/common/MnHeader";
import MnFooter from "@/components/common/MnFooter";

export default {
  components: {
    MnHeader,
    MnFooter
  }
};
</script>
