<template>
  <header class="mn-header">
    <b-navbar toggleable="lg" type="dark" variant="secondary">
      <b-navbar-brand :to="route" class="flex-grow-1">
        <b-img
          src="/images/logo_header.png"
          fluid
          alt="Logo Mobilenik"
          class="header-img d-block mr-sm-3 d-sm-inline-block"
        />
        <div class="header-title text-white pl-sm-3 d-block d-sm-inline-block">
          App Distribution Site
        </div>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse v-if="profile" id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <span class="text-light text-xl mr-3">{{
                profile.fullName
              }}</span>
              <b-avatar :src="profile.image" size="3em" />
            </template>
            <b-dropdown-item @click="onLogout()">Salir</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      profile: "auth/profile"
    }),
    route() {
      return this.profile ? { name: "admin.index" } : { name: "admin.index" };
    }
  },
  methods: {
    async onLogout() {
      await this.$gAuth.signOut();
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "auth.login" });
    }
  }
};
</script>

<style lang="scss" scoped></style>
